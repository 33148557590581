/*!
 * FullCalendar v1.6.4
 * Docs & License: http://arshaw.com/fullcalendar/
 * (c) 2013 Adam Shaw
 */

(function($, undefined) {
  var defaults = {

    // display
    defaultView: 'month',
    aspectRatio: 1.35,
    header: {
      left: 'title',
      center: '',
      right: 'today prev,next'
    },
    weekends: true,
    weekNumbers: false,
    weekNumberCalculation: 'iso',
    weekNumberTitle: 'W',

    allDayDefault: true,
    ignoreTimezone: true,

    // event ajax
    lazyFetching: true,
    startParam: 'start',
    endParam: 'end',

    // time formats
    titleFormat: {
      month: 'MMMM yyyy',
      week: "MMM d[ yyyy]{ '—'[ MMM] d yyyy}",
      day: 'dddd, MMM d, yyyy'
    },
    columnFormat: {
      month: 'ddd',
      week: 'ddd M/d',
      day: 'dddd M/d'
    },
    timeFormat: { // for event elements
      '': 'h(:mm)t' // default
    },

    // locale
    isRTL: false,
    firstDay: 0,
    monthNames: ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'],
    monthNamesShort: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
    dayNames: ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'],
    dayNamesShort: ['Dim','Lun','Mar','Mer','Jeu','Ven','Sam'],
    buttonText: {
      prev: "<span class='fc-text-arrow'>‹</span>",
      next: "<span class='fc-text-arrow'>›</span>",
      today: `Aujourd'hui`,
    },

    // jquery-ui theming
    theme: false,
    buttonIcons: {
      prev: 'circle-triangle-w',
      next: 'circle-triangle-e'
    },

    dropAccept: '*',

    handleWindowResize: true

  };

  // right-to-left defaults
  var rtlDefaults = {
    header: {
      left: 'next,prev today',
      center: '',
      right: 'title'
    },
    buttonText: {
      prev: "<span class='fc-text-arrow'>›</span>",
      next: "<span class='fc-text-arrow'>‹</span>",
    },
    buttonIcons: {
      prev: 'circle-triangle-e',
      next: 'circle-triangle-w'
    }
  };

  var fc = $.fullCalendar = { version: "1.6.4" };
  var fcViews = fc.views = {};

  $.fn.fullCalendar = function(options) {
    // method calling
    if (typeof options == 'string') {
      var args = Array.prototype.slice.call(arguments, 1);
      var res;
      this.each(function() {
        var calendar = $.data(this, 'fullCalendar');
        if (calendar && $.isFunction(calendar[options])) {
          var r = calendar[options].apply(calendar, args);
          if (res === undefined) {
            res = r;
          }
          if (options == 'destroy') {
            $.removeData(this, 'fullCalendar');
          }
        }
      });
      if (res !== undefined) {
        return res;
      }
      return this;
    }

    options = options || {};

    // would like to have this logic in EventManager, but needs to happen before options are recursively extended
    var eventSources = options.eventSources || [];
    delete options.eventSources;
    if (options.events) {
      eventSources.push(options.events);
      delete options.events;
    }

    options = $.extend(true, {},
      defaults,
      (options.isRTL || options.isRTL===undefined && defaults.isRTL) ? rtlDefaults : {},
      options
    );

    this.each(function(i, _element) {
      var element = $(_element);
      var calendar = new Calendar(element, options, eventSources);
      element.data('fullCalendar', calendar); // TODO: look into memory leak implications
      calendar.render();
    });

    return this;
  };


  // function for adding/overriding defaults
  function setDefaults(d) {
    $.extend(true, defaults, d);
  }

  function Calendar(element, options, eventSources) {
    var t = this;

    // exports
    t.options = options;
    t.render = render;
    t.destroy = destroy;
    t.refetchEvents = refetchEvents;
    t.reportEvents = reportEvents;
    t.reportEventChange = reportEventChange;
    t.rerenderEvents = rerenderEvents;
    t.changeView = changeView;
    t.prev = prev;
    t.next = next;
    t.today = today;
    t.formatDate = function(format, date) { return formatDate(format, date, options) };
    t.formatDates = function(format, date1, date2) { return formatDates(format, date1, date2, options) };
    t.getDate = getDate;
    t.getView = getView;
    t.option = option;
    t.trigger = trigger;

    // imports
    EventManager.call(t, options, eventSources);
    var isFetchNeeded = t.isFetchNeeded;
    var fetchEvents = t.fetchEvents;

    // locals
    var _element = element[0];
    var header;
    var headerElement;
    var content;
    var currentView;
    var elementOuterWidth;
    var suggestedViewHeight;
    var resizeUID = 0;
    var ignoreWindowResize = 0;
    var date = new Date();
    var events = [];

    /* Main Rendering
    -----------------------------------------------------------------------------*/

    setYMD(date, options.year, options.month, options.date);

    function render(inc) {
      if (!content) {
        initialRender();
      }
      else if (elementVisible()) {
        // mainly for the public API
        calcSize();
        _renderView(inc);
      }
    }

    function initialRender() {
      tm = options.theme ? 'ui' : 'fc';
      element.addClass('fc');
      if (options.isRTL) {
        element.addClass('fc-rtl');
      }
      else {
        element.addClass('fc-ltr');
      }
      if (options.theme) {
        element.addClass('ui-widget');
      }

      content = $("<div class='fc-content' style='position:relative'/>")
        .prependTo(element);

      header = new Header(t, options);
      headerElement = header.render();
      if (headerElement) {
        element.prepend(headerElement);
      }

      changeView(options.defaultView);

      if (options.handleWindowResize) {
        $(window).resize(windowResize);
      }

      // needed for IE in a 0x0 iframe, b/c when it is resized, never triggers a windowResize
      if (!bodyVisible()) {
        lateRender();
      }
    }


    // called when we know the calendar couldn't be rendered when it was initialized,
    // but we think it's ready now
    function lateRender() {
      setTimeout(function() { // IE7 needs this so dimensions are calculated correctly
        if (!currentView.start && bodyVisible()) { // !currentView.start makes sure this never happens more than once
          renderView();
        }
      },0);
    }

    function destroy() {

      if (currentView) {
        trigger('viewDestroy', currentView, currentView, currentView.element);
        currentView.triggerEventDestroy();
      }

      $(window).unbind('resize', windowResize);

      header.destroy();
      content.remove();
      element.removeClass('fc fc-rtl ui-widget');
    }


    function elementVisible() {
      return element.is(':visible');
    }


    function bodyVisible() {
      return $('body').is(':visible');
    }

    /* View Rendering
    -----------------------------------------------------------------------------*/
    function changeView(newViewName) {
      if (currentView) {
        trigger('viewDestroy', currentView, currentView, currentView.element);
        currentView.triggerEventDestroy(); // trigger 'eventDestroy' for each event
        freezeContentHeight();
        currentView.element.remove();
        header.deactivateButton(currentView.name);
      }

      header.activateButton(newViewName);

      currentView = new fcViews[newViewName](
        $("<div class='fc-view fc-view-" + newViewName + "' style='position:relative'/>")
          .appendTo(content),
        t // the calendar object
      );

      renderView();
      unfreezeContentHeight();
    }

    function renderView(inc) {
      if (
        !currentView.start || // never rendered before
        inc || date < currentView.start || date >= currentView.end // or new date range
      ) {
        if (elementVisible()) {
          _renderView(inc);
        }
      }
    }


    function _renderView(inc) { // assumes elementVisible
      ignoreWindowResize++;

      if (currentView.start) { // already been rendered?
        trigger('viewDestroy', currentView, currentView, currentView.element);
        clearEvents();
      }

      freezeContentHeight();
      currentView.render(date, inc || 0); // the view's render method ONLY renders the skeleton, nothing else
      setSize();
      unfreezeContentHeight();
      (currentView.afterRender || noop)();

      updateTitle();
      updateTodayButton();

      trigger('viewRender', currentView, currentView, currentView.element);
      currentView.trigger('viewDisplay', _element); // deprecated

      ignoreWindowResize--;

      getAndRenderEvents();
    }



    /* Resizing
    -----------------------------------------------------------------------------*/


    function updateSize() {
      if (elementVisible()) {
        clearEvents();
        calcSize();
        setSize();
        renderEvents();
      }
    }


    function calcSize() { // assumes elementVisible
      if (options.contentHeight) {
        suggestedViewHeight = options.contentHeight;
      }
      else if (options.height) {
        suggestedViewHeight = options.height - (headerElement ? headerElement.height() : 0) - vsides(content);
      }
      else {
        suggestedViewHeight = Math.round(content.width() / Math.max(options.aspectRatio, .5));
      }
    }


    function setSize() { // assumes elementVisible

      if (suggestedViewHeight === undefined) {
        calcSize(); // for first time
      }

      ignoreWindowResize++;
      currentView.setHeight(suggestedViewHeight);
      currentView.setWidth(content.width());
      ignoreWindowResize--;

      elementOuterWidth = element.outerWidth();
    }


    function windowResize() {
      if (!ignoreWindowResize) {
        if (currentView.start) { // view has already been rendered
          var uid = ++resizeUID;
          setTimeout(function() { // add a delay
            if (uid == resizeUID && !ignoreWindowResize && elementVisible()) {
              if (elementOuterWidth != (elementOuterWidth = element.outerWidth())) {
                ignoreWindowResize++; // in case the windowResize callback changes the height
                updateSize();
                currentView.trigger('windowResize', _element);
                ignoreWindowResize--;
              }
            }
          }, 200);
        }else{
          // calendar must have been initialized in a 0x0 iframe that has just been resized
          lateRender();
        }
      }
    }



    /* Event Fetching/Rendering
    -----------------------------------------------------------------------------*/
    // TODO: going forward, most of this stuff should be directly handled by the view


    function refetchEvents() { // can be called as an API method
      clearEvents();
      fetchAndRenderEvents();
    }


    function rerenderEvents(modifiedEventID) { // can be called as an API method
      clearEvents();
      renderEvents(modifiedEventID);
    }


    function renderEvents(modifiedEventID) { // TODO: remove modifiedEventID hack
      if (elementVisible()) {
        currentView.setEventData(events); // for View.js, TODO: unify with renderEvents
        currentView.renderEvents(events, modifiedEventID); // actually render the DOM elements
        currentView.trigger('eventAfterAllRender');
      }
    }


    function clearEvents() {
      currentView.triggerEventDestroy(); // trigger 'eventDestroy' for each event
      currentView.clearEvents(); // actually remove the DOM elements
      currentView.clearEventData(); // for View.js, TODO: unify with clearEvents
    }


    function getAndRenderEvents() {
      if (!options.lazyFetching || isFetchNeeded(currentView.visStart, currentView.visEnd)) {
        fetchAndRenderEvents();
      }
      else {
        renderEvents();
      }
    }


    function fetchAndRenderEvents() {
      fetchEvents(currentView.visStart, currentView.visEnd);
        // ... will call reportEvents
        // ... which will call renderEvents
    }


    // called when event data arrives
    function reportEvents(_events) {
      events = _events;
      renderEvents();
    }


    // called when a single event's data has been changed
    function reportEventChange(eventID) {
      rerenderEvents(eventID);
    }



    /* Header Updating
    -----------------------------------------------------------------------------*/


    function updateTitle() {
      header.updateTitle(currentView.title);
    }


    function updateTodayButton() {
      var today = new Date();
      if (today >= currentView.start && today < currentView.end) {
        header.disableButton('today');
      }
      else {
        header.enableButton('today');
      }
    }

    /* Date
    -----------------------------------------------------------------------------*/
    /**
     * Action au clic sur le bouton "mois précédent"
     */
    function prev() {
      renderView(-1);
    }
    /**
     * Action au clic sur le bouton "mois suivant"
     */
    function next() {
      renderView(1);
    }
    /**
     * Action au clic sur le bouton "Aujourd'hui"
     */
    function today() {
      date = new Date();
      renderView();
    }


    function getDate() {
      return cloneDate(date);
    }

    /* Height "Freezing"
    -----------------------------------------------------------------------------*/
    function freezeContentHeight() {
      content.css({
        width: '100%',
        height: content.height(),
        overflow: 'hidden'
      });
    }
    function unfreezeContentHeight() {
      content.css({
        width: '',
        height: '',
        overflow: ''
      });
    }

    /* Misc
    -----------------------------------------------------------------------------*/
    function getView() {
      return currentView;
    }
    function option(name, value) {
      if (value === undefined) {
        return options[name];
      }
      if (name == 'height' || name == 'contentHeight' || name == 'aspectRatio') {
        options[name] = value;
        updateSize();
      }
    }
    function trigger(name, thisObj) {
      if (options[name]) {
        return options[name].apply(
          thisObj || _element,
          Array.prototype.slice.call(arguments, 2)
        );
      }
    }
  }

  function Header(calendar, options) {
    var t = this;

    // exports
    t.render = render;
    t.destroy = destroy;
    t.updateTitle = updateTitle;
    t.activateButton = activateButton;
    t.deactivateButton = deactivateButton;
    t.disableButton = disableButton;
    t.enableButton = enableButton;

    // locals
    var element = $([]);
    var tm;

    function render() {
      tm = options.theme ? 'ui' : 'fc';
      var sections = options.header;
      if (sections) {
        element = $("<table class='fc-header' style='width:100%'/>")
          .append(
            $("<tr/>")
              .append(renderSection('left'))
              .append(renderSection('center'))
              .append(renderSection('right'))
          );
        return element;
      }
    }

    function destroy() {
      element.remove();
    }

    function renderSection(position) {
      var e = $("<td class='fc-header-" + position + "'/>");
      var buttonStr = options.header[position];
      if (buttonStr) {
        $.each(buttonStr.split(' '), function(i) {
          if (i > 0) {
            e.append("<span class='fc-header-space'/>");
          }
          var prevButton;
          $.each(this.split(','), function(j, buttonName) {
            if (buttonName == 'title') {
              e.append("<span class='fc-header-title'><h2> </h2></span>");
              if (prevButton) {
                prevButton.addClass(tm + '-corner-right');
              }
              prevButton = null;
            }else{
              var buttonClick;
              if (calendar[buttonName]) {
                buttonClick = calendar[buttonName]; // calendar method
              }
              if (buttonClick) {
                var icon = options.theme ? smartProperty(options.buttonIcons, buttonName) : null; // why are we using smartProperty here?
                var text = smartProperty(options.buttonText, buttonName); // why are we using smartProperty here?
                var button = $(
                  "<span class='fc-button fc-button-" + buttonName + " " + tm + "-state-default'>" +
                    (icon ?
                      "<span class='fc-icon-wrap'>" +
                        "<span class='ui-icon ui-icon-" + icon + "'/>" +
                      "</span>" :
                      text
                      ) +
                  "</span>"
                  )
                  .click(function() {
                    if (!button.hasClass(tm + '-state-disabled')) {
                      buttonClick();
                    }
                  })
                  .mousedown(function() {
                    button
                      .not('.' + tm + '-state-active')
                      .not('.' + tm + '-state-disabled')
                      .addClass(tm + '-state-down');
                  })
                  .mouseup(function() {
                    button.removeClass(tm + '-state-down');
                  })
                  .hover(
                    function() {
                      button
                        .not('.' + tm + '-state-active')
                        .not('.' + tm + '-state-disabled')
                        .addClass(tm + '-state-hover');
                    },
                    function() {
                      button
                        .removeClass(tm + '-state-hover')
                        .removeClass(tm + '-state-down');
                    }
                  )
                  .appendTo(e);
                disableTextSelection(button);
                if (!prevButton) {
                  button.addClass(tm + '-corner-left');
                }
                prevButton = button;
              }
            }
          });
          if (prevButton) {
            prevButton.addClass(tm + '-corner-right');
          }
        });
      }
      return e;
    }


    function updateTitle(html) {
      element.find('h2')
        .html(html);
    }


    function activateButton(buttonName) {
      element.find('span.fc-button-' + buttonName)
        .addClass(tm + '-state-active');
    }


    function deactivateButton(buttonName) {
      element.find('span.fc-button-' + buttonName)
        .removeClass(tm + '-state-active');
    }


    function disableButton(buttonName) {
      element.find('span.fc-button-' + buttonName)
        .addClass(tm + '-state-disabled');
    }


    function enableButton(buttonName) {
      element.find('span.fc-button-' + buttonName)
        .removeClass(tm + '-state-disabled');
    }
  }

  fc.sourceNormalizers = [];
  fc.sourceFetchers = [];

  var ajaxDefaults = {
    dataType: 'json',
    cache: false
  };

  var eventGUID = 1;

  function EventManager(options, _sources) {
    var t = this;

    // exports
    t.isFetchNeeded = isFetchNeeded;
    t.fetchEvents = fetchEvents;
    t.renderEvent = renderEvent;
    t.normalizeEvent = normalizeEvent;

    // imports
    var trigger = t.trigger;
    var getView = t.getView;
    var reportEvents = t.reportEvents;

    // locals
    var stickySource = { events: [] };
    var sources = [ stickySource ];
    var rangeStart, rangeEnd;
    var currentFetchID = 0;
    var pendingSourceCnt = 0;
    var loadingLevel = 0;
    var cache = [];

    for (var i=0; i<_sources.length; i++) {
      _addEventSource(_sources[i]);
    }

    /* Fetching
    -----------------------------------------------------------------------------*/

    function isFetchNeeded(start, end) {
      return !rangeStart || start < rangeStart || end > rangeEnd;
    }
    function fetchEvents(start, end) {
      rangeStart = start;
      rangeEnd = end;
      cache = [];
      var fetchID = ++currentFetchID;
      var len = sources.length;
      pendingSourceCnt = len;
      for (var i=0; i<len; i++) {
        fetchEventSource(sources[i], fetchID);
      }
    }
    function fetchEventSource(source, fetchID) {
      _fetchEventSource(source, function(events) {
        if (fetchID == currentFetchID) {
          if (events) {

            if (options.eventDataTransform) {
              events = $.map(events, options.eventDataTransform);
            }
            if (source.eventDataTransform) {
              events = $.map(events, source.eventDataTransform);
            }
            // TODO: this technique is not ideal for static array event sources.
            //  For arrays, we'll want to process all events right in the beginning, then never again.

            for (var i=0; i<events.length; i++) {
              events[i].source = source;
              normalizeEvent(events[i]);
            }
            cache = cache.concat(events);
          }
          pendingSourceCnt--;
          if (!pendingSourceCnt) {
            reportEvents(cache);
          }
        }
      });
    }
    function _fetchEventSource(source, callback) {
      var i;
      var fetchers = fc.sourceFetchers;
      var res;
      for (i=0; i<fetchers.length; i++) {
        res = fetchers[i](source, rangeStart, rangeEnd, callback);
        if (res === true) {
          // the fetcher is in charge. made its own async request
          return;
        }
        else if (typeof res == 'object') {
          // the fetcher returned a new source. process it
          _fetchEventSource(res, callback);
          return;
        }
      }
      var events = source.events;
      if (events) {
        if ($.isFunction(events)) {
          pushLoading();
          events(cloneDate(rangeStart), cloneDate(rangeEnd), function(events) {
            callback(events);
            popLoading();
          });
        }
        else if ($.isArray(events)) {
          callback(events);
        }
        else {
          callback();
        }
      }else{
        var url = source.url;
        if (url) {
          var success = source.success;
          var error = source.error;
          var complete = source.complete;

          // retrieve any outbound GET/POST $.ajax data from the options
          var customData;
          if ($.isFunction(source.data)) {
            // supplied as a function that returns a key/value object
            customData = source.data();
          }
          else {
            // supplied as a straight key/value object
            customData = source.data;
          }

          // use a copy of the custom data so we can modify the parameters
          // and not affect the passed-in object.
          var data = $.extend({}, customData || {});

          var startParam = firstDefined(source.startParam, options.startParam);
          var endParam = firstDefined(source.endParam, options.endParam);
          if (startParam) {
            data[startParam] = Math.round(+rangeStart / 1000);
          }
          if (endParam) {
            data[endParam] = Math.round(+rangeEnd / 1000);
          }

          pushLoading();
          $.ajax($.extend({}, ajaxDefaults, source, {
            data: data,
            success: function(events) {
              events = events || [];
              var res = applyAll(success, this, arguments);
              if ($.isArray(res)) {
                events = res;
              }
              callback(events);
            },
            error: function() {
              applyAll(error, this, arguments);
              callback();
            },
            complete: function() {
              applyAll(complete, this, arguments);
              popLoading();
            }
          }));
        }else{
          callback();
        }
      }
    }

    /* Sources
    -----------------------------------------------------------------------------*/
    function _addEventSource(source) {
      if ($.isFunction(source) || $.isArray(source)) {
        source = { events: source };
      }
      else if (typeof source == 'string') {
        source = { url: source };
      }
      if (typeof source == 'object') {
        normalizeSource(source);
        sources.push(source);
        return source;
      }
    }

    /* Manipulation
    -----------------------------------------------------------------------------*/
    function renderEvent(event, stick) {
      normalizeEvent(event);
      if (!event.source) {
        if (stick) {
          stickySource.events.push(event);
          event.source = stickySource;
        }
        cache.push(event);
      }
      reportEvents(cache);
    }

    /* Loading State
    -----------------------------------------------------------------------------*/
    function pushLoading() {
      if (!loadingLevel++) {
        trigger('loading', null, true, getView());
      }
    }
    function popLoading() {
      if (!--loadingLevel) {
        trigger('loading', null, false, getView());
      }
    }

    /* Event Normalization
    -----------------------------------------------------------------------------*/
    function normalizeEvent(event) {
      var source = event.source || {};
      var ignoreTimezone = firstDefined(source.ignoreTimezone, options.ignoreTimezone);
      event._id = event._id || (event.id === undefined ? '_fc' + eventGUID++ : event.id + '');
      if (event.date) {
        if (!event.start) {
          event.start = event.date;
        }
        delete event.date;
      }
      event._start = cloneDate(event.start = parseDate(event.start, ignoreTimezone));
      event.end = parseDate(event.end, ignoreTimezone);
      if (event.end && event.end <= event.start) {
        event.end = null;
      }
      event._end = event.end ? cloneDate(event.end) : null;
      if (event.allDay === undefined) {
        event.allDay = firstDefined(source.allDayDefault, options.allDayDefault);
      }
      if (event.className) {
        if (typeof event.className == 'string') {
          event.className = event.className.split(/\s+/);
        }
      }else{
        event.className = [];
      }
      // TODO: if there is no start date, return false to indicate an invalid event
    }



    /* Utils
    ------------------------------------------------------------------------------*/
    function normalizeSource(source) {
      if (source.className) {
        // TODO: repeat code, same code for event classNames
        if (typeof source.className == 'string') {
          source.className = source.className.split(/\s+/);
        }
      }else{
        source.className = [];
      }
      var normalizers = fc.sourceNormalizers;
      for (var i=0; i<normalizers.length; i++) {
        normalizers[i](source);
      }
    }
    function getSourcePrimitive(source) {
      return ((typeof source == 'object') ? (source.events || source.url) : '') || source;
    }
  }

  fc.addDays = addDays;
  fc.cloneDate = cloneDate;
  fc.parseDate = parseDate;
  fc.parseISO8601 = parseISO8601;
  fc.parseTime = parseTime;
  fc.formatDate = formatDate;
  fc.formatDates = formatDates;

  /* Date Math
  -----------------------------------------------------------------------------*/
  var dayIDs = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
    DAY_MS = 86400000,
    HOUR_MS = 3600000;

  function addMonths(d, n, keepTime) { // prevents day overflow/underflow
    if (+d) { // prevent infinite looping on invalid dates
      var m = d.getMonth() + n,
        check = cloneDate(d);
      check.setDate(1);
      check.setMonth(m);
      d.setMonth(m);
      if (!keepTime) {
        clearTime(d);
      }
      while (d.getMonth() != check.getMonth()) {
        d.setDate(d.getDate() + (d < check ? 1 : -1));
      }
    }
    return d;
  }
  function addDays(d, n, keepTime) { // deals with daylight savings
    if (+d) {
      var dd = d.getDate() + n,
        check = cloneDate(d);
      check.setHours(9); // set to middle of day
      check.setDate(dd);
      d.setDate(dd);
      if (!keepTime) {
        clearTime(d);
      }
      fixDate(d, check);
    }
    return d;
  }
  function fixDate(d, check) { // force d to be on check's YMD, for daylight savings purposes
    if (+d) { // prevent infinite looping on invalid dates
      while (d.getDate() != check.getDate()) {
        d.setTime(+d + (d < check ? 1 : -1) * HOUR_MS);
      }
    }
  }
  function addMinutes(d, n) {
    d.setMinutes(d.getMinutes() + n);
    return d;
  }

  function clearTime(d) {
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d;
  }

  function cloneDate(d, dontKeepTime) {
    if (dontKeepTime) {
      return clearTime(new Date(+d));
    }
    return new Date(+d);
  }

  function dayDiff(d1, d2) { // d1 - d2
    return Math.round((cloneDate(d1, true) - cloneDate(d2, true)) / DAY_MS);
  }

  function setYMD(date, y, m, d) {
    if (y !== undefined && y != date.getFullYear()) {
      date.setDate(1);
      date.setMonth(0);
      date.setFullYear(y);
    }
    if (m !== undefined && m != date.getMonth()) {
      date.setDate(1);
      date.setMonth(m);
    }
    if (d !== undefined) {
      date.setDate(d);
    }
  }

  /* Date Parsing
  -----------------------------------------------------------------------------*/
  function parseDate(s, ignoreTimezone) { // ignoreTimezone defaults to true
    if (typeof s == 'object') { // already a Date object
      return s;
    }
    if (typeof s == 'number') { // a UNIX timestamp
      return new Date(s * 1000);
    }
    if (typeof s == 'string') {
      if (s.match(/^\d+(\.\d+)?$/)) { // a UNIX timestamp
        return new Date(parseFloat(s) * 1000);
      }
      if (ignoreTimezone === undefined) {
        ignoreTimezone = true;
      }
      return parseISO8601(s, ignoreTimezone) || (s ? new Date(s) : null);
    }
    // TODO: never return invalid dates (like from new Date(<string>)), return null instead
    return null;
  }

  function parseISO8601(s, ignoreTimezone) { // ignoreTimezone defaults to false
    // derived from http://delete.me.uk/2005/03/iso8601.html
    // TODO: for a know glitch/feature, read tests/issue_206_parseDate_dst.html
    var m = s.match(/^([0-9]{4})(-([0-9]{2})(-([0-9]{2})([T ]([0-9]{2}):([0-9]{2})(:([0-9]{2})(\.([0-9]+))?)?(Z|(([-+])([0-9]{2})(:?([0-9]{2}))?))?)?)?)?$/);
    if (!m) {
      return null;
    }
    var date = new Date(m[1], 0, 1);
    if (ignoreTimezone || !m[13]) {
      var check = new Date(m[1], 0, 1, 9, 0);
      if (m[3]) {
        date.setMonth(m[3] - 1);
        check.setMonth(m[3] - 1);
      }
      if (m[5]) {
        date.setDate(m[5]);
        check.setDate(m[5]);
      }
      fixDate(date, check);
      if (m[7]) {
        date.setHours(m[7]);
      }
      if (m[8]) {
        date.setMinutes(m[8]);
      }
      if (m[10]) {
        date.setSeconds(m[10]);
      }
      if (m[12]) {
        date.setMilliseconds(Number("0." + m[12]) * 1000);
      }
      fixDate(date, check);
    }else{
      date.setUTCFullYear(
        m[1],
        m[3] ? m[3] - 1 : 0,
        m[5] || 1
      );
      date.setUTCHours(
        m[7] || 0,
        m[8] || 0,
        m[10] || 0,
        m[12] ? Number("0." + m[12]) * 1000 : 0
      );
      if (m[14]) {
        var offset = Number(m[16]) * 60 + (m[18] ? Number(m[18]) : 0);
        offset *= m[15] == '-' ? 1 : -1;
        date = new Date(+date + (offset * 60 * 1000));
      }
    }
    return date;
  }


  function parseTime(s) { // returns minutes since start of day
    if (typeof s == 'number') { // an hour
      return s * 60;
    }
    if (typeof s == 'object') { // a Date object
      return s.getHours() * 60 + s.getMinutes();
    }
    var m = s.match(/(\d+)(?::(\d+))?\s*(\w+)?/);
    if (m) {
      var h = parseInt(m[1], 10);
      if (m[3]) {
        h %= 12;
        if (m[3].toLowerCase().charAt(0) == 'p') {
          h += 12;
        }
      }
      return h * 60 + (m[2] ? parseInt(m[2], 10) : 0);
    }
  }



  /* Date Formatting
  -----------------------------------------------------------------------------*/
  // TODO: use same function formatDate(date, [date2], format, [options])


  function formatDate(date, format, options) {
    return formatDates(date, null, format, options);
  }


  function formatDates(date1, date2, format, options) {
    options = options || defaults;
    var date = date1,
      otherDate = date2,
      i, len = format.length, c,
      i2, formatter,
      res = '';
    for (i=0; i<len; i++) {
      c = format.charAt(i);
      if (c == "'") {
        for (i2=i+1; i2<len; i2++) {
          if (format.charAt(i2) == "'") {
            if (date) {
              if (i2 == i+1) {
                res += "'";
              }else{
                res += format.substring(i+1, i2);
              }
              i = i2;
            }
            break;
          }
        }
      }
      else if (c == '(') {
        for (i2=i+1; i2<len; i2++) {
          if (format.charAt(i2) == ')') {
            var subres = formatDate(date, format.substring(i+1, i2), options);
            if (parseInt(subres.replace(/\D/, ''), 10)) {
              res += subres;
            }
            i = i2;
            break;
          }
        }
      }
      else if (c == '[') {
        for (i2=i+1; i2<len; i2++) {
          if (format.charAt(i2) == ']') {
            var subformat = format.substring(i+1, i2);
            var subres = formatDate(date, subformat, options);
            if (subres != formatDate(otherDate, subformat, options)) {
              res += subres;
            }
            i = i2;
            break;
          }
        }
      }
      else if (c == '{') {
        date = date2;
        otherDate = date1;
      }
      else if (c == '}') {
        date = date1;
        otherDate = date2;
      }
      else {
        for (i2=len; i2>i; i2--) {
          if (formatter = dateFormatters[format.substring(i, i2)]) {
            if (date) {
              res += formatter(date, options);
            }
            i = i2 - 1;
            break;
          }
        }
        if (i2 == i) {
          if (date) {
            res += c;
          }
        }
      }
    }
    return res;
  };


  var dateFormatters = {
    s	: function(d)	{ return d.getSeconds() },
    ss	: function(d)	{ return zeroPad(d.getSeconds()) },
    m	: function(d)	{ return d.getMinutes() },
    mm	: function(d)	{ return zeroPad(d.getMinutes()) },
    h	: function(d)	{ return d.getHours() % 12 || 12 },
    hh	: function(d)	{ return zeroPad(d.getHours() % 12 || 12) },
    H	: function(d)	{ return d.getHours() },
    HH	: function(d)	{ return zeroPad(d.getHours()) },
    d	: function(d)	{ return d.getDate() },
    dd	: function(d)	{ return zeroPad(d.getDate()) },
    ddd	: function(d,o)	{ return o.dayNamesShort[d.getDay()] },
    dddd: function(d,o)	{ return o.dayNames[d.getDay()] },
    M	: function(d)	{ return d.getMonth() + 1 },
    MM	: function(d)	{ return zeroPad(d.getMonth() + 1) },
    MMM	: function(d,o)	{ return o.monthNamesShort[d.getMonth()] },
    MMMM: function(d,o)	{ return o.monthNames[d.getMonth()] },
    yy	: function(d)	{ return (d.getFullYear()+'').substring(2) },
    yyyy: function(d)	{ return d.getFullYear() },
    t	: function(d)	{ return d.getHours() < 12 ? 'a' : 'p' },
    tt	: function(d)	{ return d.getHours() < 12 ? 'am' : 'pm' },
    T	: function(d)	{ return d.getHours() < 12 ? 'A' : 'P' },
    TT	: function(d)	{ return d.getHours() < 12 ? 'AM' : 'PM' },
    u	: function(d)	{ return formatDate(d, "yyyy-MM-dd'T'HH:mm:ss'Z'") },
    S	: function(d)	{
      var date = d.getDate();
      if (date > 10 && date < 20) {
        return 'th';
      }
      return ['st', 'nd', 'rd'][date%10-1] || 'th';
    },
    w   : function(d, o) { // local
      return o.weekNumberCalculation(d);
    },
    W   : function(d) { // ISO
      return iso8601Week(d);
    }
  };
  fc.dateFormatters = dateFormatters;


  /* thanks jQuery UI (https://github.com/jquery/jquery-ui/blob/master/ui/jquery.ui.datepicker.js)
   *
   * Set as calculateWeek to determine the week of the year based on the ISO 8601 definition.
   * `date` - the date to get the week for
   * `number` - the number of the week within the year that contains this date
   */
  function iso8601Week(date) {
    var time;
    var checkDate = new Date(date.getTime());

    // Find Thursday of this week starting on Monday
    checkDate.setDate(checkDate.getDate() + 4 - (checkDate.getDay() || 7));

    time = checkDate.getTime();
    checkDate.setMonth(0); // Compare with Jan 1
    checkDate.setDate(1);
    return Math.floor(Math.round((time - checkDate) / 86400000) / 7) + 1;
  }


  ;;

  fc.applyAll = applyAll;


  /* Event Date Math
  -----------------------------------------------------------------------------*/


  function exclEndDay(event) {
    if (event.end) {
      return _exclEndDay(event.end, event.allDay);
    }else{
      return addDays(cloneDate(event.start), 1);
    }
  }


  function _exclEndDay(end, allDay) {
    end = cloneDate(end);
    return allDay || end.getHours() || end.getMinutes() ? addDays(end, 1) : clearTime(end);
    // why don't we check for seconds/ms too?
  }



  /* Event Element Binding
  -----------------------------------------------------------------------------*/


  function lazySegBind(container, segs, bindHandlers) {
    container.unbind('mouseover').mouseover(function(ev) {
      var parent=ev.target, e,
        i, seg;
      while (parent != this) {
        e = parent;
        parent = parent.parentNode;
      }
      if ((i = e._fci) !== undefined) {
        e._fci = undefined;
        seg = segs[i];
        bindHandlers(seg.event, seg.element, seg);
        $(ev.target).trigger(ev);
      }
      ev.stopPropagation();
    });
  }



  /* Element Dimensions
  -----------------------------------------------------------------------------*/


  function setOuterWidth(element, width, includeMargins) {
    for (var i=0, e; i<element.length; i++) {
      e = $(element[i]);
      e.width(Math.max(0, width - hsides(e, includeMargins)));
    }
  }

  function hsides(element, includeMargins) {
    return hpadding(element) + hborders(element) + (includeMargins ? hmargins(element) : 0);
  }


  function hpadding(element) {
    return (parseFloat($.css(element[0], 'paddingLeft', true)) || 0) +
           (parseFloat($.css(element[0], 'paddingRight', true)) || 0);
  }


  function hmargins(element) {
    return (parseFloat($.css(element[0], 'marginLeft', true)) || 0) +
           (parseFloat($.css(element[0], 'marginRight', true)) || 0);
  }


  function hborders(element) {
    return (parseFloat($.css(element[0], 'borderLeftWidth', true)) || 0) +
           (parseFloat($.css(element[0], 'borderRightWidth', true)) || 0);
  }


  function vsides(element, includeMargins) {
    return vpadding(element) +  vborders(element) + (includeMargins ? vmargins(element) : 0);
  }


  function vpadding(element) {
    return (parseFloat($.css(element[0], 'paddingTop', true)) || 0) +
           (parseFloat($.css(element[0], 'paddingBottom', true)) || 0);
  }


  function vmargins(element) {
    return (parseFloat($.css(element[0], 'marginTop', true)) || 0) +
           (parseFloat($.css(element[0], 'marginBottom', true)) || 0);
  }


  function vborders(element) {
    return (parseFloat($.css(element[0], 'borderTopWidth', true)) || 0) +
           (parseFloat($.css(element[0], 'borderBottomWidth', true)) || 0);
  }



  /* Misc Utils
  -----------------------------------------------------------------------------*/


  //TODO: arraySlice
  //TODO: isFunction, grep ?


  function noop() { }


  function dateCompare(a, b) {
    return a - b;
  }


  function arrayMax(a) {
    return Math.max.apply(Math, a);
  }


  function zeroPad(n) {
    return (n < 10 ? '0' : '') + n;
  }


  function smartProperty(obj, name) { // get a camel-cased/namespaced property of an object
    if (obj[name] !== undefined) {
      return obj[name];
    }
    var parts = name.split(/(?=[A-Z])/),
      i=parts.length-1, res;
    for (; i>=0; i--) {
      res = obj[parts[i].toLowerCase()];
      if (res !== undefined) {
        return res;
      }
    }
    return obj[''];
  }


  function htmlEscape(s) {
    return s.replace(/&/g, '&')
      .replace(/</g, '<')
      .replace(/>/g, '>')
      .replace(/'/g, '\'')
      .replace(/"/g, '"')
      .replace(/\n/g, '<br />');
  }


  function disableTextSelection(element) {
    element
      .attr('unselectable', 'on')
      .css('MozUserSelect', 'none')
      .bind('selectstart.ui', function() { return false; });
  }

  function markFirstLast(e) {
    e.children()
      .removeClass('fc-first fc-last')
      .filter(':first-child')
        .addClass('fc-first')
      .end()
      .filter(':last-child')
        .addClass('fc-last');
  }


  function getSkinCss(event, opt) {
    var source = event.source || {};
    var eventColor = event.color;
    var sourceColor = source.color;
    var optionColor = opt('eventColor');
    var backgroundColor =
      event.backgroundColor ||
      eventColor ||
      source.backgroundColor ||
      sourceColor ||
      opt('eventBackgroundColor') ||
      optionColor;
    var borderColor =
      event.borderColor ||
      eventColor ||
      source.borderColor ||
      sourceColor ||
      opt('eventBorderColor') ||
      optionColor;
    var textColor =
      event.textColor ||
      source.textColor ||
      opt('eventTextColor');
    var statements = [];
    if (backgroundColor) {
      statements.push('background-color:' + backgroundColor);
    }
    if (borderColor) {
      statements.push('border-color:' + borderColor);
    }
    if (textColor) {
      statements.push('color:' + textColor);
    }
    return statements.join(';');
  }


  function applyAll(functions, thisObj, args) {
    if ($.isFunction(functions)) {
      functions = [ functions ];
    }
    if (functions) {
      var i;
      var ret;
      for (i=0; i<functions.length; i++) {
        ret = functions[i].apply(thisObj, args) || ret;
      }
      return ret;
    }
  }


  function firstDefined() {
    for (var i=0; i<arguments.length; i++) {
      if (arguments[i] !== undefined) {
        return arguments[i];
      }
    }
  }


  ;;

  fcViews.month = MonthView;

  function MonthView(element, calendar) {
    var t = this;


    // exports
    t.render = render;


    // imports
    BasicView.call(t, element, calendar, 'month');
    var opt = t.opt;
    var renderBasic = t.renderBasic;
    var skipHiddenDays = t.skipHiddenDays;
    var getCellsPerWeek = t.getCellsPerWeek;
    var formatDate = calendar.formatDate;


    function render(date, delta) {

      if (delta) {
        addMonths(date, delta);
        date.setDate(1);
      }

      var firstDay = opt('firstDay');

      var start = cloneDate(date, true);
      start.setDate(1);

      var end = addMonths(cloneDate(start), 1);

      var visStart = cloneDate(start);
      addDays(visStart, -((visStart.getDay() - firstDay + 7) % 7));
      skipHiddenDays(visStart);

      var visEnd = cloneDate(end);
      addDays(visEnd, (7 - visEnd.getDay() + firstDay) % 7);
      skipHiddenDays(visEnd, -1, true);

      var colCnt = getCellsPerWeek();
      var rowCnt = Math.round(dayDiff(visEnd, visStart) / 7); // should be no need for Math.round

      if (opt('weekMode') == 'fixed') {
        addDays(visEnd, (6 - rowCnt) * 7); // add weeks to make up for it
        rowCnt = 6;
      }

      t.title = formatDate(start, opt('titleFormat'));

      t.start = start;
      t.end = end;
      t.visStart = visStart;
      t.visEnd = visEnd;

      renderBasic(rowCnt, colCnt, true);
    }


  }

  setDefaults({
    weekMode: 'fixed'
  });


  function BasicView(element, calendar, viewName) {
    var t = this;


    // exports
    t.renderBasic = renderBasic;
    t.setHeight = setHeight;
    t.setWidth = setWidth;
    t.renderDayOverlay = renderDayOverlay;
    t.defaultSelectionEnd = defaultSelectionEnd;
    t.renderSelection = renderSelection;
    t.clearSelection = clearSelection;
    t.reportDayClick = reportDayClick; // for selection (kinda hacky)
    t.defaultEventEnd = defaultEventEnd;
    t.getHoverListener = function() { return hoverListener };
    t.colLeft = colLeft;
    t.colRight = colRight;
    t.colContentLeft = colContentLeft;
    t.colContentRight = colContentRight;
    t.getIsCellAllDay = function() { return true };
    t.allDayRow = allDayRow;
    t.getRowCnt = function() { return rowCnt };
    t.getColCnt = function() { return colCnt };
    t.getColWidth = function() { return colWidth };
    t.getDaySegmentContainer = function() { return daySegmentContainer };


    // imports
    View.call(t, element, calendar, viewName);
    OverlayManager.call(t);
    BasicEventRenderer.call(t);
    var opt = t.opt;
    var trigger = t.trigger;
    var renderOverlay = t.renderOverlay;
    var clearOverlays = t.clearOverlays;
    var daySelectionMousedown = t.daySelectionMousedown;
    var cellToDate = t.cellToDate;
    var dateToCell = t.dateToCell;
    var rangeToSegments = t.rangeToSegments;
    var formatDate = calendar.formatDate;


    // locals

    var table;
    var head;
    var headCells;
    var body;
    var bodyRows;
    var bodyCells;
    var bodyFirstCells;
    var firstRowCellInners;
    var firstRowCellContentInners;
    var daySegmentContainer;

    var viewWidth;
    var viewHeight;
    var colWidth;
    var weekNumberWidth;

    var rowCnt, colCnt;
    var showNumbers;
    var coordinateGrid;
    var hoverListener;
    var colPositions;
    var colContentPositions;

    var tm;
    var colFormat;
    var showWeekNumbers;
    var weekNumberTitle;
    var weekNumberFormat;



    /* Rendering
    ------------------------------------------------------------*/


    disableTextSelection(element.addClass('fc-grid'));


    function renderBasic(_rowCnt, _colCnt, _showNumbers) {
      rowCnt = _rowCnt;
      colCnt = _colCnt;
      showNumbers = _showNumbers;
      updateOptions();

      if (!body) {
        buildEventContainer();
      }

      buildTable();
    }


    function updateOptions() {
      tm = opt('theme') ? 'ui' : 'fc';
      colFormat = opt('columnFormat');

      // week # options. (TODO: bad, logic also in other views)
      showWeekNumbers = opt('weekNumbers');
      weekNumberTitle = opt('weekNumberTitle');
      if (opt('weekNumberCalculation') != 'iso') {
        weekNumberFormat = "w";
      }
      else {
        weekNumberFormat = "W";
      }
    }


    function buildEventContainer() {
      daySegmentContainer =
        $("<div class='fc-event-container' style='position:absolute;z-index:8;top:0;left:0'/>")
          .appendTo(element);
    }


    function buildTable() {
      var html = buildTableHTML();

      if (table) {
        table.remove();
      }
      table = $(html).appendTo(element);

      head = table.find('thead');
      headCells = head.find('.fc-day-header');
      body = table.find('tbody');
      bodyRows = body.find('tr');
      bodyCells = body.find('.fc-day');
      bodyFirstCells = bodyRows.find('td:first-child');

      firstRowCellInners = bodyRows.eq(0).find('.fc-day > div');
      firstRowCellContentInners = bodyRows.eq(0).find('.fc-day-content > div');

      markFirstLast(head.add(head.find('tr'))); // marks first+last tr/th's
      markFirstLast(bodyRows); // marks first+last td's
      bodyRows.eq(0).addClass('fc-first');
      bodyRows.filter(':last').addClass('fc-last');

      bodyCells.each(function(i, _cell) {
        var date = cellToDate(
          Math.floor(i / colCnt),
          i % colCnt
        );
        trigger('dayRender', t, date, $(_cell));
      });

      dayBind(bodyCells);
    }



    /* HTML Building
    -----------------------------------------------------------*/


    function buildTableHTML() {
      var html =
        "<table class='fc-border-separate' style='width:100%' cellspacing='0'>" +
        buildHeadHTML() +
        buildBodyHTML() +
        "</table>";

      return html;
    }


    function buildHeadHTML() {
      var headerClass = tm + "-widget-header";
      var html = '';
      var col;
      var date;

      html += "<thead><tr>";

      if (showWeekNumbers) {
        html +=
          "<th class='fc-week-number " + headerClass + "'>" +
          htmlEscape(weekNumberTitle) +
          "</th>";
      }

      for (col=0; col<colCnt; col++) {
        date = cellToDate(0, col);
        html +=
          "<th class='fc-day-header fc-" + dayIDs[date.getDay()] + " " + headerClass + "'>" +
          htmlEscape(formatDate(date, colFormat)) +
          "</th>";
      }

      html += "</tr></thead>";

      return html;
    }


    function buildBodyHTML() {
      var contentClass = tm + "-widget-content";
      var html = '';
      var row;
      var col;
      var date;

      html += "<tbody>";

      for (row=0; row<rowCnt; row++) {

        html += "<tr class='fc-week'>";

        if (showWeekNumbers) {
          date = cellToDate(row, 0);
          html +=
            "<td class='fc-week-number " + contentClass + "'>" +
            "<div>" +
            htmlEscape(formatDate(date, weekNumberFormat)) +
            "</div>" +
            "</td>";
        }

        for (col=0; col<colCnt; col++) {
          date = cellToDate(row, col);
          html += buildCellHTML(date);
        }

        html += "</tr>";
      }

      html += "</tbody>";

      return html;
    }


    function buildCellHTML(date) {
      var contentClass = tm + "-widget-content";
      var month = t.start.getMonth();
      var today = clearTime(new Date());
      var html = '';
      var classNames = [
        'fc-day',
        'fc-' + dayIDs[date.getDay()],
        contentClass
      ];

      if (date.getMonth() != month) {
        classNames.push('fc-other-month');
      }
      if (+date == +today) {
        classNames.push(
          'fc-today',
          tm + '-state-highlight'
        );
      }
      else if (date < today) {
        classNames.push('fc-past');
      }
      else {
        classNames.push('fc-future');
      }

      html +=
        "<td" +
        " class='" + classNames.join(' ') + "'" +
        " data-date='" + formatDate(date, 'yyyy-MM-dd') + "'" +
        ">" +
        "<div>";

      if (showNumbers) {
        html += "<div class='fc-day-number'>" + date.getDate() + "</div>";
      }

      html +=
        "<div class='fc-day-content'>" +
        "<div style='position:relative'> </div>" +
        "</div>" +
        "</div>" +
        "</td>";

      return html;
    }



    /* Dimensions
    -----------------------------------------------------------*/


    function setHeight(height) {
      viewHeight = height;

      var bodyHeight = viewHeight - head.height();
      var rowHeight;
      var rowHeightLast;
      var cell;

      if (opt('weekMode') == 'variable') {
        rowHeight = rowHeightLast = Math.floor(bodyHeight / (rowCnt==1 ? 2 : 6));
      }else{
        rowHeight = Math.floor(bodyHeight / rowCnt);
        rowHeightLast = bodyHeight - rowHeight * (rowCnt-1);
      }

      bodyFirstCells.each(function(i, _cell) {
        if (i < rowCnt) {
          cell = $(_cell);
          cell.find('> div').css(
            'min-height',
            (i==rowCnt-1 ? rowHeightLast : rowHeight) - vsides(cell)
          );
        }
      });

    }


    function setWidth(width) {
      viewWidth = width;
      colPositions.clear();
      colContentPositions.clear();

      weekNumberWidth = 0;
      if (showWeekNumbers) {
        weekNumberWidth = head.find('th.fc-week-number').outerWidth();
      }

      colWidth = Math.floor((viewWidth - weekNumberWidth) / colCnt);
      setOuterWidth(headCells.slice(0, -1), colWidth);
    }



    /* Day clicking and binding
    -----------------------------------------------------------*/


    function dayBind(days) {
      days.click(dayClick)
        .mousedown(daySelectionMousedown);
    }


    function dayClick(ev) {
      if (!opt('selectable')) { // if selectable, SelectionManager will worry about dayClick
        var date = parseISO8601($(this).data('date'));
        trigger('dayClick', this, date, true, ev);
      }
    }



    /* Semi-transparent Overlay Helpers
    ------------------------------------------------------*/
    // TODO: should be consolidated with AgendaView's methods


    function renderDayOverlay(overlayStart, overlayEnd, refreshCoordinateGrid) { // overlayEnd is exclusive

      if (refreshCoordinateGrid) {
        coordinateGrid.build();
      }

      var segments = rangeToSegments(overlayStart, overlayEnd);

      for (var i=0; i<segments.length; i++) {
        var segment = segments[i];
        dayBind(
          renderCellOverlay(
            segment.row,
            segment.leftCol,
            segment.row,
            segment.rightCol
          )
        );
      }
    }


    function renderCellOverlay(row0, col0, row1, col1) { // row1,col1 is inclusive
      var rect = coordinateGrid.rect(row0, col0, row1, col1, element);
      return renderOverlay(rect, element);
    }



    /* Selection
    -----------------------------------------------------------------------*/


    function defaultSelectionEnd(startDate, allDay) {
      return cloneDate(startDate);
    }


    function renderSelection(startDate, endDate, allDay) {
      renderDayOverlay(startDate, addDays(cloneDate(endDate), 1), true); // rebuild every time???
    }


    function clearSelection() {
      clearOverlays();
    }


    function reportDayClick(date, allDay, ev) {
      var cell = dateToCell(date);
      var _element = bodyCells[cell.row*colCnt + cell.col];
      trigger('dayClick', _element, date, allDay, ev);
    }



    /* Utilities
    --------------------------------------------------------*/


    function defaultEventEnd(event) {
      return cloneDate(event.start);
    }


    coordinateGrid = new CoordinateGrid(function(rows, cols) {
      var e, n, p;
      headCells.each(function(i, _e) {
        e = $(_e);
        n = e.offset().left;
        if (i) {
          p[1] = n;
        }
        p = [n];
        cols[i] = p;
      });
      p[1] = n + e.outerWidth();
      bodyRows.each(function(i, _e) {
        if (i < rowCnt) {
          e = $(_e);
          n = e.offset().top;
          if (i) {
            p[1] = n;
          }
          p = [n];
          rows[i] = p;
        }
      });
      p[1] = n + e.outerHeight();
    });


    hoverListener = new HoverListener(coordinateGrid);

    colPositions = new HorizontalPositionCache(function(col) {
      return firstRowCellInners.eq(col);
    });

    colContentPositions = new HorizontalPositionCache(function(col) {
      return firstRowCellContentInners.eq(col);
    });


    function colLeft(col) {
      return colPositions.left(col);
    }


    function colRight(col) {
      return colPositions.right(col);
    }


    function colContentLeft(col) {
      return colContentPositions.left(col);
    }


    function colContentRight(col) {
      return colContentPositions.right(col);
    }


    function allDayRow(i) {
      return bodyRows.eq(i);
    }
  }

  function BasicEventRenderer() {
    var t = this;
    // exports
    t.renderEvents = renderEvents;
    t.clearEvents = clearEvents;
    // imports
    DayEventRenderer.call(t);

    function renderEvents(events, modifiedEventId) {
      t.renderDayEvents(events, modifiedEventId);
    }


    function clearEvents() {
      t.getDaySegmentContainer().empty();
    }
  }

  setDefaults({
    allDaySlot: true,
    allDayText: 'all-day',
    firstHour: 6,
    slotMinutes: 30,
    defaultEventMinutes: 120,
    axisFormat: 'h(:mm)tt',
    timeFormat: {
      agenda: 'h:mm{ - h:mm}'
    },
    minTime: 0,
    maxTime: 24,
    slotEventOverlap: true
  });

  function View(element, calendar, viewName) {
    var t = this;
    // exports
    t.element = element;
    t.calendar = calendar;
    t.name = viewName;
    t.opt = opt;
    t.trigger = trigger;
    t.setEventData = setEventData;
    t.clearEventData = clearEventData;
    t.eventEnd = eventEnd;
    t.reportEventElement = reportEventElement;
    t.triggerEventDestroy = triggerEventDestroy;
    t.eventElementHandlers = eventElementHandlers;
    t.showEvents = showEvents;
    t.hideEvents = hideEvents;
    t.eventResize = eventResize;

    // imports
    var defaultEventEnd = t.defaultEventEnd;
    var normalizeEvent = calendar.normalizeEvent; // in EventManager
    var reportEventChange = calendar.reportEventChange;

    // locals
    var eventsByID = {}; // eventID mapped to array of events (there can be multiple b/c of repeating events)
    var eventElementsByID = {}; // eventID mapped to array of jQuery elements
    var eventElementCouples = []; // array of objects, { event, element } // TODO: unify with segment system
    var options = calendar.options;

    function opt(name, viewNameOverride) {
      var v = options[name];
      if ($.isPlainObject(v)) {
        return smartProperty(v, viewNameOverride || viewName);
      }
      return v;
    }

    function trigger(name, thisObj) {
      return calendar.trigger.apply(
        calendar,
        [name, thisObj || t].concat(Array.prototype.slice.call(arguments, 2), [t])
      );
    }

    /* Event Data
    ------------------------------------------------------------------------------*/
    function setEventData(events) { // events are already normalized at this point
      eventsByID = {};
      var i, len=events.length, event;
      for (i=0; i<len; i++) {
        event = events[i];
        if (eventsByID[event._id]) {
          eventsByID[event._id].push(event);
        }else{
          eventsByID[event._id] = [event];
        }
      }
    }

    function clearEventData() {
      eventsByID = {};
      eventElementsByID = {};
      eventElementCouples = [];
    }

    // returns a Date object for an event's end
    function eventEnd(event) {
      return event.end ? cloneDate(event.end) : defaultEventEnd(event);
    }

    /* Event Elements
    ------------------------------------------------------------------------------*/
    function reportEventElement(event, element) {
      eventElementCouples.push({ event: event, element: element });
      if (eventElementsByID[event._id]) {
        eventElementsByID[event._id].push(element);
      }else{
        eventElementsByID[event._id] = [element];
      }
    }
    function triggerEventDestroy() {
      $.each(eventElementCouples, function(i, couple) {
        t.trigger('eventDestroy', couple.event, couple.event, couple.element);
      });
    }


    // attaches eventClick, eventMouseover, eventMouseout
    function eventElementHandlers(event, eventElement) {
      eventElement
        .click(function(ev) {
          return trigger('eventClick', this, event, ev);
        })
        .hover(
          function(ev) {
            trigger('eventMouseover', this, event, ev);
          },
          function(ev) {
            trigger('eventMouseout', this, event, ev);
          }
        );
    }
    function showEvents(event, exceptElement) {
      eachEventElement(event, exceptElement, 'show');
    }
    function hideEvents(event, exceptElement) {
      eachEventElement(event, exceptElement, 'hide');
    }
    function eachEventElement(event, exceptElement, funcName) {
      // NOTE: there may be multiple events per ID (repeating events)
      // and multiple segments per event
      var elements = eventElementsByID[event._id],
        i, len = elements.length;
      for (i=0; i<len; i++) {
        if (!exceptElement || elements[i][0] != exceptElement[0]) {
          elements[i][funcName]();
        }
      }
    }

    /* Event Modification Reporting
    ---------------------------------------------------------------------------------*/
    function eventResize(e, event, dayDelta, minuteDelta, ev, ui) {
      var eventId = event._id;
      elongateEvents(eventsByID[eventId], dayDelta, minuteDelta);
      trigger(
        'eventResize',
        e,
        event,
        dayDelta,
        minuteDelta,
        function() {
          // TODO: investigate cases where this inverse technique might not work
          elongateEvents(eventsByID[eventId], -dayDelta, -minuteDelta);
          reportEventChange(eventId);
        },
        ev,
        ui
      );
      reportEventChange(eventId);
    }



    /* Event Modification Math
    ---------------------------------------------------------------------------------*/


    function moveEvents(events, dayDelta, minuteDelta, allDay) {
      minuteDelta = minuteDelta || 0;
      for (var e, len=events.length, i=0; i<len; i++) {
        e = events[i];
        if (allDay !== undefined) {
          e.allDay = allDay;
        }
        addMinutes(addDays(e.start, dayDelta, true), minuteDelta);
        if (e.end) {
          e.end = addMinutes(addDays(e.end, dayDelta, true), minuteDelta);
        }
        normalizeEvent(e, options);
      }
    }


    function elongateEvents(events, dayDelta, minuteDelta) {
      minuteDelta = minuteDelta || 0;
      for (var e, len=events.length, i=0; i<len; i++) {
        e = events[i];
        e.end = addMinutes(addDays(eventEnd(e), dayDelta, true), minuteDelta);
        normalizeEvent(e, options);
      }
    }

    // exports
    t.isHiddenDay = isHiddenDay;
    t.skipHiddenDays = skipHiddenDays;
    t.getCellsPerWeek = getCellsPerWeek;
    t.dateToCell = dateToCell;
    t.dateToDayOffset = dateToDayOffset;
    t.dayOffsetToCellOffset = dayOffsetToCellOffset;
    t.cellOffsetToCell = cellOffsetToCell;
    t.cellToDate = cellToDate;
    t.cellToCellOffset = cellToCellOffset;
    t.cellOffsetToDayOffset = cellOffsetToDayOffset;
    t.dayOffsetToDate = dayOffsetToDate;
    t.rangeToSegments = rangeToSegments;

    // internals
    var hiddenDays = opt('hiddenDays') || []; // array of day-of-week indices that are hidden
    var isHiddenDayHash = []; // is the day-of-week hidden? (hash with day-of-week-index -> bool)
    var cellsPerWeek;
    var dayToCellMap = []; // hash from dayIndex -> cellIndex, for one week
    var cellToDayMap = []; // hash from cellIndex -> dayIndex, for one week
    var isRTL = opt('isRTL');

    // initialize important internal variables
    (function() {

      if (opt('weekends') === false) {
        hiddenDays.push(0, 6); // 0=sunday, 6=saturday
      }

      // Loop through a hypothetical week and determine which
      // days-of-week are hidden. Record in both hashes (one is the reverse of the other).
      for (var dayIndex=0, cellIndex=0; dayIndex<7; dayIndex++) {
        dayToCellMap[dayIndex] = cellIndex;
        isHiddenDayHash[dayIndex] = $.inArray(dayIndex, hiddenDays) != -1;
        if (!isHiddenDayHash[dayIndex]) {
          cellToDayMap[cellIndex] = dayIndex;
          cellIndex++;
        }
      }

      cellsPerWeek = cellIndex;
      if (!cellsPerWeek) {
        throw 'invalid hiddenDays'; // all days were hidden? bad.
      }

    })();


    // Is the current day hidden?
    // `day` is a day-of-week index (0-6), or a Date object
    function isHiddenDay(day) {
      if (typeof day == 'object') {
        day = day.getDay();
      }
      return isHiddenDayHash[day];
    }


    function getCellsPerWeek() {
      return cellsPerWeek;
    }


    // Keep incrementing the current day until it is no longer a hidden day.
    // If the initial value of `date` is not a hidden day, don't do anything.
    // Pass `isExclusive` as `true` if you are dealing with an end date.
    // `inc` defaults to `1` (increment one day forward each time)
    function skipHiddenDays(date, inc, isExclusive) {
      inc = inc || 1;
      while (
        isHiddenDayHash[ ( date.getDay() + (isExclusive ? inc : 0) + 7 ) % 7 ]
      ) {
        addDays(date, inc);
      }
    }


    //
    // TRANSFORMATIONS: cell -> cell offset -> day offset -> date
    //

    // cell -> date (combines all transformations)
    // Possible arguments:
    // - row, col
    // - { row:#, col: # }
    function cellToDate() {
      var cellOffset = cellToCellOffset.apply(null, arguments);
      var dayOffset = cellOffsetToDayOffset(cellOffset);
      var date = dayOffsetToDate(dayOffset);
      return date;
    }

    // cell -> cell offset
    // Possible arguments:
    // - row, col
    // - { row:#, col:# }
    function cellToCellOffset(row, col) {
      var colCnt = t.getColCnt();

      // rtl variables. wish we could pre-populate these. but where?
      var dis = isRTL ? -1 : 1;
      var dit = isRTL ? colCnt - 1 : 0;

      if (typeof row == 'object') {
        col = row.col;
        row = row.row;
      }
      var cellOffset = row * colCnt + (col * dis + dit); // column, adjusted for RTL (dis & dit)

      return cellOffset;
    }

    // cell offset -> day offset
    function cellOffsetToDayOffset(cellOffset) {
      var day0 = t.visStart.getDay(); // first date's day of week
      cellOffset += dayToCellMap[day0]; // normlize cellOffset to beginning-of-week
      return Math.floor(cellOffset / cellsPerWeek) * 7 // # of days from full weeks
        + cellToDayMap[ // # of days from partial last week
          (cellOffset % cellsPerWeek + cellsPerWeek) % cellsPerWeek // crazy math to handle negative cellOffsets
        ]
        - day0; // adjustment for beginning-of-week normalization
    }

    // day offset -> date (JavaScript Date object)
    function dayOffsetToDate(dayOffset) {
      var date = cloneDate(t.visStart);
      addDays(date, dayOffset);
      return date;
    }


    //
    // TRANSFORMATIONS: date -> day offset -> cell offset -> cell
    //

    // date -> cell (combines all transformations)
    function dateToCell(date) {
      var dayOffset = dateToDayOffset(date);
      var cellOffset = dayOffsetToCellOffset(dayOffset);
      var cell = cellOffsetToCell(cellOffset);
      return cell;
    }

    // date -> day offset
    function dateToDayOffset(date) {
      return dayDiff(date, t.visStart);
    }

    // day offset -> cell offset
    function dayOffsetToCellOffset(dayOffset) {
      var day0 = t.visStart.getDay(); // first date's day of week
      dayOffset += day0; // normalize dayOffset to beginning-of-week
      return Math.floor(dayOffset / 7) * cellsPerWeek // # of cells from full weeks
        + dayToCellMap[ // # of cells from partial last week
          (dayOffset % 7 + 7) % 7 // crazy math to handle negative dayOffsets
        ]
        - dayToCellMap[day0]; // adjustment for beginning-of-week normalization
    }

    // cell offset -> cell (object with row & col keys)
    function cellOffsetToCell(cellOffset) {
      var colCnt = t.getColCnt();

      // rtl variables. wish we could pre-populate these. but where?
      var dis = isRTL ? -1 : 1;
      var dit = isRTL ? colCnt - 1 : 0;

      var row = Math.floor(cellOffset / colCnt);
      var col = ((cellOffset % colCnt + colCnt) % colCnt) * dis + dit; // column, adjusted for RTL (dis & dit)
      return {
        row: row,
        col: col
      };
    }


    //
    // Converts a date range into an array of segment objects.
    // "Segments" are horizontal stretches of time, sliced up by row.
    // A segment object has the following properties:
    // - row
    // - cols
    // - isStart
    // - isEnd
    //
    function rangeToSegments(startDate, endDate) {
      var rowCnt = t.getRowCnt();
      var colCnt = t.getColCnt();
      var segments = []; // array of segments to return

      // day offset for given date range
      var rangeDayOffsetStart = dateToDayOffset(startDate);
      var rangeDayOffsetEnd = dateToDayOffset(endDate); // exclusive

      // first and last cell offset for the given date range
      // "last" implies inclusivity
      var rangeCellOffsetFirst = dayOffsetToCellOffset(rangeDayOffsetStart);
      var rangeCellOffsetLast = dayOffsetToCellOffset(rangeDayOffsetEnd) - 1;

      // loop through all the rows in the view
      for (var row=0; row<rowCnt; row++) {

        // first and last cell offset for the row
        var rowCellOffsetFirst = row * colCnt;
        var rowCellOffsetLast = rowCellOffsetFirst + colCnt - 1;

        // get the segment's cell offsets by constraining the range's cell offsets to the bounds of the row
        var segmentCellOffsetFirst = Math.max(rangeCellOffsetFirst, rowCellOffsetFirst);
        var segmentCellOffsetLast = Math.min(rangeCellOffsetLast, rowCellOffsetLast);

        // make sure segment's offsets are valid and in view
        if (segmentCellOffsetFirst <= segmentCellOffsetLast) {

          // translate to cells
          var segmentCellFirst = cellOffsetToCell(segmentCellOffsetFirst);
          var segmentCellLast = cellOffsetToCell(segmentCellOffsetLast);

          // view might be RTL, so order by leftmost column
          var cols = [ segmentCellFirst.col, segmentCellLast.col ].sort();

          // Determine if segment's first/last cell is the beginning/end of the date range.
          // We need to compare "day offset" because "cell offsets" are often ambiguous and
          // can translate to multiple days, and an edge case reveals itself when we the
          // range's first cell is hidden (we don't want isStart to be true).
          var isStart = cellOffsetToDayOffset(segmentCellOffsetFirst) == rangeDayOffsetStart;
          var isEnd = cellOffsetToDayOffset(segmentCellOffsetLast) + 1 == rangeDayOffsetEnd; // +1 for comparing exclusively

          segments.push({
            row: row,
            leftCol: cols[0],
            rightCol: cols[1],
            isStart: isStart,
            isEnd: isEnd
          });
        }
      }

      return segments;
    }


  }

  ;;

  function DayEventRenderer() {
    var t = this;


    // exports
    t.renderDayEvents = renderDayEvents;


    // imports
    var opt = t.opt;
    var trigger = t.trigger;
    var reportEventElement = t.reportEventElement;
    var eventElementHandlers = t.eventElementHandlers;
    var getRowCnt = t.getRowCnt;
    var getColCnt = t.getColCnt;
    var allDayRow = t.allDayRow; // TODO: rename
    var colLeft = t.colLeft;
    var colRight = t.colRight;
    var colContentLeft = t.colContentLeft;
    var colContentRight = t.colContentRight;
    var getDaySegmentContainer = t.getDaySegmentContainer;
    var formatDates = t.calendar.formatDates;
    var rangeToSegments = t.rangeToSegments;

    // Render `events` onto the calendar, attach mouse event handlers, and call the `eventAfterRender` callback for each.
    // Mouse event will be lazily applied, except if the event has an ID of `modifiedEventId`.
    // Can only be called when the event container is empty (because it wipes out all innerHTML).
    function renderDayEvents(events, modifiedEventId) {

      // do the actual rendering. Receive the intermediate "segment" data structures.
      var segments = _renderDayEvents(
        events,
        false, // don't append event elements
        true // set the heights of the rows
      );

      // report the elements to the View, for general drag/resize utilities
      segmentElementEach(segments, function(segment, element) {
        reportEventElement(segment.event, element);
      });

      // attach mouse handlers
      attachHandlers(segments, modifiedEventId);

      // call `eventAfterRender` callback for each event
      segmentElementEach(segments, function(segment, element) {
        trigger('eventAfterRender', segment.event, segment.event, element);
      });
    }

    // Render events onto the calendar. Only responsible for the VISUAL aspect.
    // Not responsible for attaching handlers or calling callbacks.
    // Set `doAppend` to `true` for rendering elements without clearing the existing container.
    // Set `doRowHeights` to allow setting the height of each row, to compensate for vertical event overflow.
    function _renderDayEvents(events, doAppend, doRowHeights) {

      // where the DOM nodes will eventually end up
      var finalContainer = getDaySegmentContainer();

      // the container where the initial HTML will be rendered.
      // If `doAppend`==true, uses a temporary container.
      var renderContainer = doAppend ? $("<div/>") : finalContainer;

      var segments = buildSegments(events);
      var html;
      var elements;

      // calculate the desired `left` and `width` properties on each segment object
      calculateHorizontals(segments);

      // build the HTML string. relies on `left` property
      html = buildHTML(segments);

      // render the HTML. innerHTML is considerably faster than jQuery's .html()
      renderContainer[0].innerHTML = html;

      // retrieve the individual elements
      elements = renderContainer.children();

      // if we were appending, and thus using a temporary container,
      // re-attach elements to the real container.
      if (doAppend) {
        finalContainer.append(elements);
      }

      // assigns each element to `segment.event`, after filtering them through user callbacks
      resolveElements(segments, elements);

      // Calculate the left and right padding+margin for each element.
      // We need this for setting each element's desired outer width, because of the W3C box model.
      // It's important we do this in a separate pass from acually setting the width on the DOM elements
      // because alternating reading/writing dimensions causes reflow for every iteration.
      segmentElementEach(segments, function(segment, element) {
        segment.hsides = hsides(element, true); // include margins = `true`
      });

      // Set the width of each element
      segmentElementEach(segments, function(segment, element) {
        element.width(
          Math.max(0, segment.outerWidth - segment.hsides)
        );
      });

      // Grab each element's outerHeight (setVerticals uses this).
      // To get an accurate reading, it's important to have each element's width explicitly set already.
      segmentElementEach(segments, function(segment, element) {
        segment.outerHeight = element.outerHeight(true); // include margins = `true`
      });

      // Set the top coordinate on each element (requires segment.outerHeight)
      setVerticals(segments, doRowHeights);

      return segments;
    }


    // Generate an array of "segments" for all events.
    function buildSegments(events) {
      var segments = [];
      for (var i=0; i<events.length; i++) {
        var eventSegments = buildSegmentsForEvent(events[i]);
        segments.push.apply(segments, eventSegments); // append an array to an array
      }
      return segments;
    }


    // Generate an array of segments for a single event.
    // A "segment" is the same data structure that View.rangeToSegments produces,
    // with the addition of the `event` property being set to reference the original event.
    function buildSegmentsForEvent(event) {
      var startDate = event.start;
      var endDate = exclEndDay(event);
      var segments = rangeToSegments(startDate, endDate);
      for (var i=0; i<segments.length; i++) {
        segments[i].event = event;
      }
      return segments;
    }


    // Sets the `left` and `outerWidth` property of each segment.
    // These values are the desired dimensions for the eventual DOM elements.
    function calculateHorizontals(segments) {
      var isRTL = opt('isRTL');
      for (var i=0; i<segments.length; i++) {
        var segment = segments[i];

        // Determine functions used for calulating the elements left/right coordinates,
        // depending on whether the view is RTL or not.
        // NOTE:
        // colLeft/colRight returns the coordinate butting up the edge of the cell.
        // colContentLeft/colContentRight is indented a little bit from the edge.
        var leftFunc = (isRTL ? segment.isEnd : segment.isStart) ? colContentLeft : colLeft;
        var rightFunc = (isRTL ? segment.isStart : segment.isEnd) ? colContentRight : colRight;

        var left = leftFunc(segment.leftCol);
        var right = rightFunc(segment.rightCol);
        segment.left = left;
        segment.outerWidth = right - left;
      }
    }


    // Build a concatenated HTML string for an array of segments
    function buildHTML(segments) {
      var html = '';
      for (var i=0; i<segments.length; i++) {
        html += buildHTMLForSegment(segments[i]);
      }
      return html;
    }


    // Build an HTML string for a single segment.
    // Relies on the following properties:
    // - `segment.event` (from `buildSegmentsForEvent`)
    // - `segment.left` (from `calculateHorizontals`)
    function buildHTMLForSegment(segment) {
      var html = '';
      var isRTL = opt('isRTL');
      var event = segment.event;
      var url = event.url;

      // generate the list of CSS classNames
      var classNames = [ 'fc-event', 'fc-event-hori' ];
      if (segment.isStart) {
        classNames.push('fc-event-start');
      }
      if (segment.isEnd) {
        classNames.push('fc-event-end');
      }
      // use the event's configured classNames
      // guaranteed to be an array via `normalizeEvent`
      classNames = classNames.concat(event.className);
      if (event.source) {
        // use the event's source's classNames, if specified
        classNames = classNames.concat(event.source.className || []);
      }

      // generate a semicolon delimited CSS string for any of the "skin" properties
      // of the event object (`backgroundColor`, `borderColor` and such)
      var skinCss = getSkinCss(event, opt);

      if (url) {
        html += "<a href='" + htmlEscape(url) + "'";
      }else{
        html += "<div";
      }
      html +=
        " class='" + classNames.join(' ') + "'" +
        " id='" + event._id + "'" +
        " style=" +
          "'" +
          "position:absolute;" +
          "left:" + segment.left + "px;" +
          skinCss +
          "'" +
        ">" +
        "<div class='fc-event-inner'>";
      if (!event.allDay && segment.isStart) {
        html +=
          "<span class='fc-event-time'>" +
          htmlEscape(
            formatDates(event.start, event.end, opt('timeFormat'))
          ) +
          "</span>";
      }
      html +=
        "<span class='fc-event-title'>" +
        htmlEscape(event.title || '') +
        "</span>" +
        "</div>";
      html += "</" + (url ? "a" : "div") + ">";
      return html;
    }


    // Associate each segment (an object) with an element (a jQuery object),
    // by setting each `segment.element`.
    // Run each element through the `eventRender` filter, which allows developers to
    // modify an existing element, supply a new one, or cancel rendering.
    function resolveElements(segments, elements) {
      for (var i=0; i<segments.length; i++) {
        var segment = segments[i];
        var event = segment.event;
        var element = elements.eq(i);

        // call the trigger with the original element
        var triggerRes = trigger('eventRender', event, event, element);

        if (triggerRes === false) {
          // if `false`, remove the event from the DOM and don't assign it to `segment.event`
          element.remove();
        }
        else {
          if (triggerRes && triggerRes !== true) {
            // the trigger returned a new element, but not `true` (which means keep the existing element)

            // re-assign the important CSS dimension properties that were already assigned in `buildHTMLForSegment`
            triggerRes = $(triggerRes)
              .css({
                position: 'absolute',
                left: segment.left
              });

            element.replaceWith(triggerRes);
            element = triggerRes;
          }

          segment.element = element;
        }
      }
    }



    /* Top-coordinate Methods
    -------------------------------------------------------------------------------------------------*/


    // Sets the "top" CSS property for each element.
    // If `doRowHeights` is `true`, also sets each row's first cell to an explicit height,
    // so that if elements vertically overflow, the cell expands vertically to compensate.
    function setVerticals(segments, doRowHeights) {
      var rowContentHeights = calculateVerticals(segments); // also sets segment.top
      var rowContentElements = getRowContentElements(); // returns 1 inner div per row
      var rowContentTops = [];

      // Set each row's height by setting height of first inner div
      if (doRowHeights) {
        for (var i=0; i<rowContentElements.length; i++) {
          rowContentElements[i].height(rowContentHeights[i]);
        }
      }

      // Get each row's top, relative to the views's origin.
      // Important to do this after setting each row's height.
      for (var i=0; i<rowContentElements.length; i++) {
        rowContentTops.push(
          rowContentElements[i].position().top
        );
      }

      // Set each segment element's CSS "top" property.
      // Each segment object has a "top" property, which is relative to the row's top, but...
      segmentElementEach(segments, function(segment, element) {
        element.css(
          'top',
          rowContentTops[segment.row] + segment.top // ...now, relative to views's origin
        );
      });
    }


    // Calculate the "top" coordinate for each segment, relative to the "top" of the row.
    // Also, return an array that contains the "content" height for each row
    // (the height displaced by the vertically stacked events in the row).
    // Requires segments to have their `outerHeight` property already set.
    function calculateVerticals(segments) {
      var rowCnt = getRowCnt();
      var colCnt = getColCnt();
      var rowContentHeights = []; // content height for each row
      var segmentRows = buildSegmentRows(segments); // an array of segment arrays, one for each row

      for (var rowI=0; rowI<rowCnt; rowI++) {
        var segmentRow = segmentRows[rowI];

        // an array of running total heights for each column.
        // initialize with all zeros.
        var colHeights = [];
        for (var colI=0; colI<colCnt; colI++) {
          colHeights.push(0);
        }

        // loop through every segment
        for (var segmentI=0; segmentI<segmentRow.length; segmentI++) {
          var segment = segmentRow[segmentI];

          // find the segment's top coordinate by looking at the max height
          // of all the columns the segment will be in.
          segment.top = arrayMax(
            colHeights.slice(
              segment.leftCol,
              segment.rightCol + 1 // make exclusive for slice
            )
          );

          // adjust the columns to account for the segment's height
          for (var colI=segment.leftCol; colI<=segment.rightCol; colI++) {
            colHeights[colI] = segment.top + segment.outerHeight;
          }
        }

        // the tallest column in the row should be the "content height"
        rowContentHeights.push(arrayMax(colHeights));
      }

      return rowContentHeights;
    }


    // Build an array of segment arrays, each representing the segments that will
    // be in a row of the grid, sorted by which event should be closest to the top.
    function buildSegmentRows(segments) {
      var rowCnt = getRowCnt();
      var segmentRows = [];
      var segmentI;
      var segment;
      var rowI;

      // group segments by row
      for (segmentI=0; segmentI<segments.length; segmentI++) {
        segment = segments[segmentI];
        rowI = segment.row;
        if (segment.element) { // was rendered?
          if (segmentRows[rowI]) {
            // already other segments. append to array
            segmentRows[rowI].push(segment);
          }
          else {
            // first segment in row. create new array
            segmentRows[rowI] = [ segment ];
          }
        }
      }

      // sort each row
      for (rowI=0; rowI<rowCnt; rowI++) {
        segmentRows[rowI] = sortSegmentRow(
          segmentRows[rowI] || [] // guarantee an array, even if no segments
        );
      }

      return segmentRows;
    }


    // Sort an array of segments according to which segment should appear closest to the top
    function sortSegmentRow(segments) {
      var sortedSegments = [];

      // build the subrow array
      var subrows = buildSegmentSubrows(segments);

      // flatten it
      for (var i=0; i<subrows.length; i++) {
        sortedSegments.push.apply(sortedSegments, subrows[i]); // append an array to an array
      }

      return sortedSegments;
    }


    // Take an array of segments, which are all assumed to be in the same row,
    // and sort into subrows.
    function buildSegmentSubrows(segments) {

      // Give preference to elements with certain criteria, so they have
      // a chance to be closer to the top.
      segments.sort(compareDaySegments);

      var subrows = [];
      for (var i=0; i<segments.length; i++) {
        var segment = segments[i];

        // loop through subrows, starting with the topmost, until the segment
        // doesn't collide with other segments.
        for (var j=0; j<subrows.length; j++) {
          if (!isDaySegmentCollision(segment, subrows[j])) {
            break;
          }
        }
        // `j` now holds the desired subrow index
        if (subrows[j]) {
          subrows[j].push(segment);
        }
        else {
          subrows[j] = [ segment ];
        }
      }

      return subrows;
    }


    // Return an array of jQuery objects for the placeholder content containers of each row.
    // The content containers don't actually contain anything, but their dimensions should match
    // the events that are overlaid on top.
    function getRowContentElements() {
      var i;
      var rowCnt = getRowCnt();
      var rowDivs = [];
      for (i=0; i<rowCnt; i++) {
        rowDivs[i] = allDayRow(i)
          .find('div.fc-day-content > div');
      }
      return rowDivs;
    }



    /* Mouse Handlers
    ---------------------------------------------------------------------------------------------------*/
    // TODO: better documentation!


    function attachHandlers(segments, modifiedEventId) {
      var segmentContainer = getDaySegmentContainer();

      segmentElementEach(segments, function(segment, element, i) {
        var event = segment.event;
        if (event._id === modifiedEventId) {
          bindDaySeg(event, element, segment);
        }else{
          element[0]._fci = i; // for lazySegBind
        }
      });

      lazySegBind(segmentContainer, segments, bindDaySeg);
    }


    function bindDaySeg(event, eventElement, segment) {
      // attach all other handlers.
      // needs to be after, because resizableDayEvent might stopImmediatePropagation on click
      eventElementHandlers(event, eventElement);
    }
  }



  /* Generalized Segment Utilities
  -------------------------------------------------------------------------------------------------*/
  function isDaySegmentCollision(segment, otherSegments) {
    for (var i=0; i<otherSegments.length; i++) {
      var otherSegment = otherSegments[i];
      if (
        otherSegment.leftCol <= segment.rightCol &&
        otherSegment.rightCol >= segment.leftCol
      ) {
        return true;
      }
    }
    return false;
  }
  function segmentElementEach(segments, callback) { // TODO: use in AgendaView?
    for (var i=0; i<segments.length; i++) {
      var segment = segments[i];
      var element = segment.element;
      if (element) {
        callback(segment, element, i);
      }
    }
  }
  // A cmp function for determining which segments should appear higher up
  function compareDaySegments(a, b) {
    return (b.rightCol - b.leftCol) - (a.rightCol - a.leftCol) || // put wider events first
      b.event.allDay - a.event.allDay || // if tie, put all-day events first (booleans cast to 0/1)
      a.event.start - b.event.start || // if a tie, sort by event start date
      (a.event.title || '').localeCompare(b.event.title) // if a tie, sort by event title
  }

  function OverlayManager() {
    var t = this;


    // exports
    t.renderOverlay = renderOverlay;
    t.clearOverlays = clearOverlays;


    // locals
    var usedOverlays = [];
    var unusedOverlays = [];

    function renderOverlay(rect, parent) {
      var e = unusedOverlays.shift();
      if (!e) {
        e = $("<div class='fc-cell-overlay' style='position:absolute;z-index:3'/>");
      }
      if (e[0].parentNode != parent[0]) {
        e.appendTo(parent);
      }
      usedOverlays.push(e.css(rect).show());
      return e;
    }

    function clearOverlays() {
      var e;
      while (e = usedOverlays.shift()) {
        unusedOverlays.push(e.hide().unbind());
      }
    }
  }

  function CoordinateGrid(buildFunc) {

    var t = this;
    var rows;
    var cols;


    t.build = function() {
      rows = [];
      cols = [];
      buildFunc(rows, cols);
    };


    t.cell = function(x, y) {
      var rowCnt = rows.length;
      var colCnt = cols.length;
      var i, r=-1, c=-1;
      for (i=0; i<rowCnt; i++) {
        if (y >= rows[i][0] && y < rows[i][1]) {
          r = i;
          break;
        }
      }
      for (i=0; i<colCnt; i++) {
        if (x >= cols[i][0] && x < cols[i][1]) {
          c = i;
          break;
        }
      }
      return (r>=0 && c>=0) ? { row:r, col:c } : null;
    };
    t.rect = function(row0, col0, row1, col1, originElement) { // row1,col1 is inclusive
      var origin = originElement.offset();
      return {
        top: rows[row0][0] - origin.top,
        left: cols[col0][0] - origin.left,
        width: cols[col1][1] - cols[col0][0],
        height: rows[row1][1] - rows[row0][0]
      };
    };
  }

  function HoverListener(coordinateGrid) {
    var t = this;
    var bindType;
    var change;
    var firstCell;
    var cell;


    t.start = function(_change, ev, _bindType) {
      change = _change;
      firstCell = cell = null;
      coordinateGrid.build();
      mouse(ev);
      bindType = _bindType || 'mousemove';
      $(document).bind(bindType, mouse);
    };


    function mouse(ev) {
      _fixUIEvent(ev); // see below
      var newCell = coordinateGrid.cell(ev.pageX, ev.pageY);
      if (!newCell != !cell || newCell && (newCell.row != cell.row || newCell.col != cell.col)) {
        if (newCell) {
          if (!firstCell) {
            firstCell = newCell;
          }
          change(newCell, firstCell, newCell.row-firstCell.row, newCell.col-firstCell.col);
        }else{
          change(newCell, firstCell);
        }
        cell = newCell;
      }
    }

    t.stop = function() {
      $(document).unbind(bindType, mouse);
      return cell;
    };
  }

  function _fixUIEvent(event) { // for issue 1168
    if (event.pageX === undefined) {
      event.pageX = event.originalEvent.pageX;
      event.pageY = event.originalEvent.pageY;
    }
  }

  function HorizontalPositionCache(getElement) {
    var t = this,
      elements = {},
      lefts = {},
      rights = {};

    function e(i) {
      return elements[i] = elements[i] || getElement(i);
    }

    t.left = function(i) {
      return lefts[i] = lefts[i] === undefined ? e(i).position().left : lefts[i];
    };

    t.right = function(i) {
      return rights[i] = rights[i] === undefined ? t.left(i) + e(i).width() : rights[i];
    };

    t.clear = function() {
      elements = {};
      lefts = {};
      rights = {};
    };
  }
  })(jQuery);